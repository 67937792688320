  .ribbon {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    margin-left: var(--marginLeft);
    width: fit-content;
    --r: 1.1em;
    padding-right: calc(var(--r) + 0.5em);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    line-height: var(--lineHeight);
    background: var(--ribbonColor);
    border: 0.5px solid transparent;
    clip-path: polygon(-100vw 0, 100% 0, calc(100% - var(--r)) 50%, 100% 100%, -100vw 100%);
    overflow: hidden; /* Ensure content respects the border-radius */
  }

  
  .ribbon-text {
    margin-left: 6px;
  }