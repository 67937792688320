@import '../../../semantic-ui/site/globals/site.variables';

.DashboardHomeNew {
    padding: 10px 20px;
    max-width: 1440px;
    margin: 0 auto;
    padding-bottom: 70px;
    .icons-links-container {
        display: flex;
        flex-wrap: wrap;

        .link {
            text-align: center;
            width: 20%;
            margin: 16px 0;
            box-sizing: border-box;
            padding: 0 8px;
            word-wrap: break-word;
            .round-img {
                min-width: 60px;
                max-width: 120px;
            }
            .round-img.contact-img {
                max-width: 100%;
            }
        }
    }
    .coming-soon-title {
        border-bottom: 1px solid #69b546;
        margin: 0 auto;
        max-width: 1280px;
        padding-top: 8px;
    }
}

.Reports {
    display: flex;
    // flex-wrap: wrap;
    a {
        font-size: 18px;
    }

    .report-panel-row {
        display: inline-flex;
        justify-content: space-between;
        .btn-container {
            display: inline-flex;
            flex-direction: row;
        }
        .btn-container.close-btn-present {
            .create-request {
                width: 45px;
                height:45px;
                font-size: 14px;
                font-weight: 500;
                .button-icon {
                    font-size: 22px;
                }
                .text {
                    line-height: 1.16;
                    margin-top: 2px;
                    margin-bottom: -4px;
                }
            }
        }
    }
    .mt-10 {
        margin-top: 10px;
    }
    .report-panel-row.radial {
        justify-content: space-evenly;
    }

    .create-request {
        width: 100px;
        height: 100px;
        font-size: 18px;
        border: 0;
        outline: 0;
        cursor: pointer;
        text-align: center;
        line-height: 18px;
        text-align: center;
        color: @primaryColor;
        border-radius: 5px;
        margin-right: 2px;
        display: flex;
        align-items: center;
        &:hover {
            background: #eee;
        }

        .button-icon {
            font-size: 36px;
            display: block;
            margin: auto;
        }
    }

    .react-datepicker-wrapper {
        height: fit-content;
    }

    .graphs {
        display: flex;
        justify-content: space-between;
        flex: 2;
    }
}

.no-requests {
    font-size: 3em;
    line-height: 1.14;
    color: #ddd;
    text-align: center;
    padding: 0px 20px;
    font-weight: bold;
}

.Timeline {
    .ui.card {
        width: 100%;
    }
}

.justify-center {
    justify-content: center;
}
.flex-1 {
    flex: 1;
}
.flex {
    display: flex;
}
@media (max-width: 576px) {
    .Reports {
        .create-request {
            width: 80px;
            height: 80px;
            margin-bottom: 8px;

            .button-icon {
                font-size: 22px;
            }
        }
    }
}

.date-range-selector {
    display: flex;
    padding-right: 10px !important;
    padding-left: 10px !important;
    align-items: center;
    background-color: #F2F4F7 !important;
    // cursor: pointer;
}

.icons-group {
    // background-color: #69b546;
    margin-left: 5px;
}

.icons-div {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.icon-with-count {
    position: relative;
    display: inline-block;
    margin-left: 5px;
}

.icon-with-count .count {
    position: absolute;
    top: -10px;
    right: -2px;
    background-color: red;
    color: white;
    font-size: 10px;
    line-height: 18px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    z-index: 99;
}

.icons-label {
    padding-left: 5px;
    margin-top: -10px;
    margin-bottom: 5px;
    color: grey;
    font-size: smaller;
}