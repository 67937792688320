.item.very.specific.userclass.UserItem {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    
    .profile-pic {
        max-width: 64px;
    }
    .profile-pic-staff img{
        width: 50px;
    }

    @media (min-width: 1600px) {
        .profile-pic-staff img{
            width: 68px;
        }
    }
    
    .center-self {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
    }
}
