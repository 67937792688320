.AssignButton {
    .menu {
        min-width: max-content !important;
    }
}

.requestAssignButton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70px;
    height: 65px;
    background-color: #183466 ;
    color: #fff;
    padding: 10px 15px;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
}
.userIcon{
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    margin: auto;
}