.cookbook-container {
    padding: 10px;

    .cookbook-header {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    .dropdown {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .icon-placement {
        position: absolute;
        right: 0;
        bottom: -10px
    }

    .clickable {
        cursor: pointer;
        text-decoration: underline;
        color: initial;
    }

    .clickable:hover {
        color: "blue"
    }

    .table-cell {
        position: relative;
    }

    .trash-icon {
        visibility: hidden;
        position: absolute;
        cursor: pointer;
        right: 0;
        top: 12px;
    }

    .table-row:hover .trash-icon {
        visibility: visible;
    }
}