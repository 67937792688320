.RequestsKanbanBoard .react-kanban-column{
  background-color: #fff;
  width: 435px;
}
.RequestsKanbanBoard ::-webkit-scrollbar {
  width: 2px !important; /* Width of the scrollbar */
}
.RequestsKanbanBoard .react-kanban-board {
  /* padding: 5px; */
  margin-left: -14px;
  overflow-y: visible !important;
}
.RequestsKanbanBoard {
    margin-top: 15px;
}
.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
.fontRoboto {
    font-family: 'Lato', sans-serif;
}